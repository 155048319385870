import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-telekom.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/telekom-glasfaservermarktung/telekom-glasfaservermarktung-hero-small.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const TelekomGlasfaservermarktung = function (props, ref) {

    const data = useStaticQuery(graphql`
        query GlasfaservermarktungQuery {
            heropic: file(relativePath: { eq: "images/projekte/telekom-glasfaservermarktung/telekom-glasfaservermarktung-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/telekom-glasfaservermarktung/telekom-glasfaservermarktung-hero.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/telekom-glasfaservermarktung/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: BLURRED, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Corporate Communications"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung", "produktion"] },
        {
          title: "Toolchain",
          objects: ["html", "css", "js"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Telekom Glasfaservermarktung"
                description="Q:marketing ist Partneragentur für die Vermarktung von Glasfaser in der Region West."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
{/* {data.heropic.childImageSharp.gatsbyImageData}*/}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Telekom Glasfaser-Vermarktung</h1>
                        <p className="introtext whitetextshadow">Q:marketing ist Regionalagentur zur Erstellung von Kommunikationsmaßnahmen für die Glasfaser-Vermarktung.</p>
                        <AnchorLink to="#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                               
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>   

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Konzeption, Gestaltung und Produktion von Werbemitteln unter Berücksichtigung der Corporate Design-Vorgaben und strategischen Ziele der Telekom.",
                        "Erstellung von Kommunikationsmaßnahmen für alle Phasen der Glasfaservermarktung.",                        
                        "Kompetenz in der T-Glasfaser Kommunikation.",
                        "Flyer, Mailings, Plakate, Animationen, Webanwendungen, Gewinnspiele, Schaufenstergestaltung, Fahrzeugbeklebung, Banner, Give-Aways"

                    ]}
                />
            </ListenModule>
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default TelekomGlasfaservermarktung